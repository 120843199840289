<template>
    <HeaderJourney />

    <div class="main-listing-page user-profile-page">
        <div class="container">
            <div class="grid ml-0 mr-0 mt-5 mb-4">
                <div class="col-12 md:col-4 lg:col-3 listing-filter-area">
                    <userProfileSetting />
                </div>
                
                <div class="col-12 md:col-8 lg:col-9">
                    <div class="right-listing-items-area">
                        <div class="tabs-listing-content">
                            <!-- Plans Not Found -->
                            <div class="tabs-listing-box company-plans plans-not-found hidden">
                                <i class="pi pi-exclamation-triangle"></i>
                                <h1>Plans not found</h1>
                            </div>
                            <!-- / Plans Not Found -->
                            
                            <!-- listing loader -->
                            <template class="hidden">
                                <div class="tabs-listing-box company-plans plans-loader" v-for="index in 3" :key="index">
                                    <div class="top-row">
                                        <Skeleton-prime shape="circle"></Skeleton-prime>
                                        <div class="top-row-right">
                                            <Skeleton-prime class="mb-2" ></Skeleton-prime>
                                            <Skeleton-prime width="10rem"  class="mb-2"></Skeleton-prime>
                                            <Skeleton-prime width="5rem"  class="mb-2"></Skeleton-prime>
                                            <Skeleton-prime class="mb-2"></Skeleton-prime>
                                        </div>
                                    </div>
                                    <div class="bottom-row">
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem" height="4rem"></Skeleton-prime>
                                    </div>
                                    <div class="bottom-row">
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                        <Skeleton-prime width="10rem"></Skeleton-prime>
                                    </div>
                                </div>
                            </template>
                            <!-- listing loader -->
                            
                            <!-- Profile listing -->
                            <div class="policy-listing-container">
                                <tabView-prime>
                                    <tabPanel-prime header="Profile" v-if="!isDesktop">
                                        <userProfileSetting />
                                    </tabPanel-prime>

                                    <tabPanel-prime header="My Quotations">
                                        <div class="policy-filter-container">
                                            <div class="card flex justify-center">
                                                <div class="flex flex-wrap gap-4">
                                                    <div class="flex items-center gap-2">
                                                        <radioButton-prime 
                                                            name="status"
                                                            value="active"
                                                            v-model="quotationSelectedStatus"
                                                            label="Active"
                                                        />
                                                        <label for="ingredient2">Active</label>
                                                    </div>
                                                    <div class="flex items-center gap-2">
                                                        <radioButton-prime 
                                                            name="status"
                                                            value="expired"
                                                            v-model="quotationSelectedStatus"
                                                            label="Expired"
                                                        />
                                                        <label for="ingredient3">Expired</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="listing-tabs-container">
                                                <button-prime class="filters p-0 listing-filter-btn-mobile" aria-label="filters"
                                                    @click="visible = true">
                                                    <span class=" px-3">Filter</span>
                                                    <img src="../assets/images/sort.svg">
                                                </button-prime>

                                                <div class="listing-tab-panel">

                                                </div>

                                                <div class="listing-sort-container">
                                                    <dropdown-prime :options="filters.sort" optionLabel="name" placeholder="Sort By" class="w-full" />
                                                </div>
                                            </div> -->
                                        </div>

                                        <div class="plan-listing-slider-view company-plans-carousel" v-if="quotationSelectedStatus === 'active'">
                                            <div class="logo-box-container">
                                                <div class="logo-box">
                                                    <img src="../assets/images/travel.png">
                                                </div>

                                                <div class="info-listing-text">
                                                    <p class="company-name-text">Travel Insurance</p>
                                                    <p class="plan-name-text">1 insurances</p>
                                                </div>
                                            </div>

                                            <swiper :slides-per-view="1" :space-between="0" :centeredSlides="true" setWrapperSize="ture" :navigation="true" :loop="false" :pagination="pagination" :modules="modules" :breakpoints="{
                                                        '@0.00': 1,
                                                        '@0.75': 1,
                                                        '@1.00': 1,
                                                        '@1.50': 1,
                                                }" @swiper="onSwiper" @slideChange="onSlideChange">
                                                <swiper-slide>
                                                    <div class="plan-listing-slider-view">
                                                        <div class="tabs-listing-box company-plans">
                                                            <div class="listing-top-header">
                                                                <div class="header-info-right">
                                                                    <div class="plan-info-top-left-column">
                                                                        <div class="currency-info">
                                                                            <div class="logo-box-container">
                                                                                <div class="logo-box">
                                                                                    <img src="../assets/images/image-40.png">
                                                                                </div>

                                                                                <div class="info-listing-text">
                                                                                    <p class="company-name-text">Alliance</p>
                                                                                </div>
                                                                            </div>

                                                                            <div class="pricing-info-container">
                                                                                <div class="annual-premium">
                                                                                    <span class="regular-text">Annual Premium</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>

                                                                                <div class="vat">
                                                                                    <span class="regular-text">VAT (5%)</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <p class="plan-name-text">Travel Inbound Gold - Multi trip (each trip should not exceed 14 days)</p>
                                                                    </div>
                                                                    
                                                                    <div class="btn-right-area">
                                                                        <button-prime label="Active" icon="pi pi-circle-fill" class="active-btn" />

                                                                        <!-- <p class="expiry-text">Expiring Soon</p> -->
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="listing-footer">
                                                                <div class="footer-likn-text">
                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-clock px-2"></i>
                                                                        <span class="px-2">Generated 07-08-2025</span>
                                                                    </button-prime>
                                                                </div>

                                                                <div class="download-box">
                                                                    <button-prime class="p-0 transparent view-btn">
                                                                        <i class="pi pi-eye"></i>
                                                                    </button-prime>

                                                                    <button-prime class="p-0 transparent download-btn">
                                                                        <i class="pi pi-download"></i>
                                                                    </button-prime>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                            </swiper>
                                        </div>

                                        <div class="plan-listing-slider-view company-plans-carousel" v-if="quotationSelectedStatus === 'expired'">
                                            <div class="logo-box-container">
                                                <div class="logo-box">
                                                    <img src="../assets/images/travel.png">
                                                </div>

                                                <div class="info-listing-text">
                                                    <p class="company-name-text">Travel Insurance</p>
                                                    <p class="plan-name-text">1 insurances</p>
                                                </div>
                                            </div>

                                            <swiper :slides-per-view="1" :space-between="0" :centeredSlides="true" setWrapperSize="ture" :navigation="true" :loop="false" :pagination="pagination" :modules="modules" :breakpoints="{
                                                        '@0.00': 1,
                                                        '@0.75': 1,
                                                        '@1.00': 1,
                                                        '@1.50': 1,
                                                }" @swiper="onSwiper" @slideChange="onSlideChange">
                                                <swiper-slide>
                                                    <div class="plan-listing-slider-view">
                                                        <div class="tabs-listing-box company-plans">
                                                            <div class="listing-top-header">
                                                                <div class="header-info-right">
                                                                    <div class="plan-info-top-left-column">
                                                                        <div class="currency-info">
                                                                            <div class="logo-box-container">
                                                                                <div class="logo-box">
                                                                                    <img src="../assets/images/image-40.png">
                                                                                </div>

                                                                                <div class="info-listing-text">
                                                                                    <p class="company-name-text">Alliance</p>
                                                                                </div>
                                                                            </div>

                                                                            <div class="pricing-info-container">
                                                                                <div class="annual-premium">
                                                                                    <span class="regular-text">Annual Premium</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>

                                                                                <div class="vat">
                                                                                    <span class="regular-text">VAT (5%)</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <p class="plan-name-text">Travel Inbound Gold - Multi trip (each trip should not exceed 14 days)</p>
                                                                    </div>
                                                                    
                                                                    <div class="btn-right-area">
                                                                        <button-prime label="Expired" class="expired-btn" />

                                                                        <!-- <p class="expiry-text">Expiring Soon</p> -->
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="listing-footer">
                                                                <div class="footer-likn-text">
                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-clock px-2"></i>
                                                                        <span class="px-2">Generated 07-08-2025</span>
                                                                    </button-prime>
                                                                </div>

                                                                <div class="download-box">
                                                                    <button-prime class="p-0 transparent view-btn">
                                                                        <i class="pi pi-eye"></i>
                                                                    </button-prime>

                                                                    <button-prime class="p-0 transparent download-btn">
                                                                        <i class="pi pi-download"></i>
                                                                    </button-prime>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                            </swiper>
                                        </div>
                                    </tabPanel-prime>

                                    <tabPanel-prime header="My Policies">
                                        <div class="policy-filter-container">
                                            <div class="card flex justify-center">
                                                <div class="flex flex-wrap gap-4">
                                                    <div class="flex items-center gap-2">
                                                        <radioButton-prime 
                                                            name="status"
                                                            value="all"
                                                            v-model="selectedStatus"
                                                            label="All" 
                                                        />
                                                        <label for="ingredient1">All</label>
                                                    </div>
                                                    <div class="flex items-center gap-2">
                                                        <radioButton-prime 
                                                            name="status"
                                                            value="active"
                                                            v-model="selectedStatus"
                                                            label="Active"
                                                        />
                                                        <label for="ingredient2">Active</label>
                                                    </div>
                                                    <div class="flex items-center gap-2">
                                                        <radioButton-prime 
                                                            name="status"
                                                            value="expired"
                                                            v-model="selectedStatus"
                                                            label="Expired"
                                                        />
                                                        <label for="ingredient3">Expired</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="plan-listing-slider-view company-plans-carousel" v-if="selectedStatus === 'all' || selectedStatus === 'active'">
                                            <div class="logo-box-container">
                                                <div class="logo-box">
                                                    <img src="../assets/images/item-motor.png">
                                                </div>

                                                <div class="info-listing-text">
                                                    <p class="company-name-text">Motor Insurance</p>
                                                    <p class="plan-name-text">3 insurances</p>
                                                </div>
                                            </div>

                                            <swiper :slides-per-view="1" :space-between="0" :centeredSlides="true" setWrapperSize="ture" :navigation="true" :loop="false" :pagination="pagination" :modules="modules" :breakpoints="{
                                                        '@0.00': 1,
                                                        '@0.75': 1,
                                                        '@1.00': 1,
                                                        '@1.50': 1,
                                                }" @swiper="onSwiper" @slideChange="onSlideChange">
                                                <swiper-slide v-for="index in 3" :key="index">
                                                    <div class="plan-listing-slider-view">
                                                        <div class="tabs-listing-box company-plans">
                                                            <div class="listing-top-header">
                                                                <div class="header-info-right">
                                                                    <div class="plan-info-top-left-column">
                                                                        <div class="currency-info">
                                                                            <div class="logo-box-container">
                                                                                <div class="logo-box">
                                                                                    <img src="../assets/images/image-39.png">
                                                                                </div>

                                                                                <div class="info-listing-text">
                                                                                    <p class="company-name-text">Orient</p>
                                                                                </div>
                                                                            </div>

                                                                            <div class="pricing-info-container"> 
                                                                                <div class="annual-premium">
                                                                                    <span class="regular-text">Annual Premium</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>

                                                                                <div class="vat">
                                                                                    <span class="regular-text">VAT (5%)</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <p class="plan-name-text">Comprehensive Plan</p>
                                                                    </div>
                                                                    
                                                                    <div class="btn-right-area">
                                                                        <button-prime label="Active" icon="pi pi-circle-fill" class="active-btn" />

                                                                        <!-- <div class="footer-download-send-btn">
                                                                            <button-prime icon="pi pi-download" label="Download" severity="secondary" outlined />
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="listing-footer">
                                                                <div class="footer-likn-text">
                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-credit-card px-2"></i>
                                                                        <span class="px-2">Purchased 07-08-2023</span>
                                                                    </button-prime>

                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-clock px-2"></i>
                                                                        <span class="px-2">Expiring 07-08-2025</span>
                                                                    </button-prime>
                                                                </div>

                                                                <div class="download-box">
                                                                    <button-prime class="p-0 transparent download-btn">
                                                                        <i class="pi pi-download"></i>
                                                                    </button-prime>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                            </swiper>
                                        </div>

                                        <div class="plan-listing-slider-view company-plans-carousel" v-if="selectedStatus === 'all' || selectedStatus === 'active'">
                                            <div class="logo-box-container">
                                                <div class="logo-box">
                                                    <img src="../assets/images/travel.png">
                                                </div>

                                                <div class="info-listing-text">
                                                    <p class="company-name-text">Travel Insurance</p>
                                                    <p class="plan-name-text">1 insurances</p>
                                                </div>
                                            </div>

                                            <swiper :slides-per-view="1" :space-between="0" :centeredSlides="true" setWrapperSize="ture" :navigation="true" :loop="false" :pagination="pagination" :modules="modules" :breakpoints="{
                                                        '@0.00': 1,
                                                        '@0.75': 1,
                                                        '@1.00': 1,
                                                        '@1.50': 1,
                                                }" @swiper="onSwiper" @slideChange="onSlideChange">
                                                <swiper-slide>
                                                    <div class="plan-listing-slider-view">
                                                        <div class="tabs-listing-box company-plans">
                                                            <div class="listing-top-header">
                                                                <div class="header-info-right">
                                                                    <div class="plan-info-top-left-column">
                                                                        <div class="currency-info">
                                                                            <div class="logo-box-container">
                                                                                <div class="logo-box">
                                                                                    <img src="../assets/images/image-40.png">
                                                                                </div>

                                                                                <div class="info-listing-text">
                                                                                    <p class="company-name-text">Alliance</p>
                                                                                </div>
                                                                            </div>

                                                                            <div class="pricing-info-container">
                                                                                <div class="annual-premium">
                                                                                    <span class="regular-text">Annual Premium</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>

                                                                                <div class="vat">
                                                                                    <span class="regular-text">VAT (5%)</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <p class="plan-name-text">Travel Inbound Gold - Multi trip (each trip should not exceed 14 days)</p>
                                                                    </div>
                                                                    
                                                                    <div class="btn-right-area">
                                                                        <button-prime label="Active" icon="pi pi-circle-fill" class="active-btn" />

                                                                        <p class="expiry-text">Expiring Soon</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="listing-footer">
                                                                <div class="footer-likn-text">
                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-credit-card px-2"></i>
                                                                        <span class="px-2">Purchased 07-08-2023</span>
                                                                    </button-prime>

                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-clock px-2"></i>
                                                                        <span class="px-2">Expiring 07-08-2025</span>
                                                                    </button-prime>
                                                                </div>

                                                                <div class="download-box">
                                                                    <button-prime class="p-0 transparent download-btn">
                                                                        <i class="pi pi-download"></i>
                                                                    </button-prime>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                            </swiper>
                                        </div>

                                        <div class="plan-listing-slider-view company-plans-carousel" v-if="selectedStatus === 'expired'">
                                            <div class="logo-box-container">
                                                <div class="logo-box">
                                                    <img src="../assets/images/item-motor.png">
                                                </div>

                                                <div class="info-listing-text">
                                                    <p class="company-name-text">Motor Insurance</p>
                                                    <p class="plan-name-text">3 insurances</p>
                                                </div>
                                            </div>

                                            <swiper :slides-per-view="1" :space-between="0" :centeredSlides="true" setWrapperSize="ture" :navigation="true" :loop="false" :pagination="pagination" :modules="modules" :breakpoints="{
                                                        '@0.00': 1,
                                                        '@0.75': 1,
                                                        '@1.00': 1,
                                                        '@1.50': 1,
                                                }" @swiper="onSwiper" @slideChange="onSlideChange">
                                                <swiper-slide v-for="index in 3" :key="index">
                                                    <div class="plan-listing-slider-view">
                                                        <div class="tabs-listing-box company-plans">
                                                            <div class="listing-top-header">
                                                                <div class="header-info-right">
                                                                    <div class="plan-info-top-left-column">
                                                                        <div class="currency-info">
                                                                            <div class="logo-box-container">
                                                                                <div class="logo-box">
                                                                                    <img src="../assets/images/image-39.png">
                                                                                </div>

                                                                                <div class="info-listing-text">
                                                                                    <p class="company-name-text">Orient</p>
                                                                                </div>
                                                                            </div>

                                                                            <div class="pricing-info-container">
                                                                                <div class="annual-premium">
                                                                                    <span class="regular-text">Annual Premium</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>

                                                                                <div class="vat">
                                                                                    <span class="regular-text">VAT (5%)</span>
                                                                                    <span class="bold-text">AED 3,300.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <p class="plan-name-text">Comprehensive Plan</p>
                                                                    </div>
                                                                    
                                                                    <div class="btn-right-area">
                                                                        <button-prime label="Expired" class="expired-btn" />

                                                                        <!-- <div class="footer-download-send-btn">
                                                                            <button-prime icon="pi pi-download" label="Download" severity="secondary" outlined />
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="listing-footer">
                                                                <div class="footer-likn-text">
                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-credit-card px-2"></i>
                                                                        <span class="px-2">Purchased 07-08-2023</span>
                                                                    </button-prime>

                                                                    <button-prime class="p-0 transparent btn-box-item">
                                                                        <i class="pi pi-clock px-2"></i>
                                                                        <span class="px-2">Expiring 07-08-2025</span>
                                                                    </button-prime>
                                                                </div>

                                                                <div class="download-box">
                                                                    <button-prime class="p-0 transparent download-btn">
                                                                        <i class="pi pi-download"></i>
                                                                    </button-prime>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                            </swiper>
                                        </div>
                                    </tabPanel-prime>
                                </tabView-prime>
                            </div>
                            <!-- / Policy listing -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <toast-prime />

    <!-- Filter Modal For Mobile -->
    <dialog-prime v-model:visible="visible" header="Filters" :style="{ width: '25rem' }" position="bottom" :modal="true"
        :draggable="false" class="listing-filter-modal-mobile">
        <div class="height-100">
            <div class="grid ml-0 mr-0 height-100">
                <div class="col-12 md:col-12 lg:col-12 listing-filter-area-mobile height-100">
                    <div class="left-filters-area">
                        <div class="filter-options-area">

                        </div>
                    </div>

                    <button-prime label="Apply filters" class="apply-filter-btn" @click="visible = false" />
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Filter Modal For Mobile -->
</template>

<script>
import HeaderJourney from '../components/Header/HeaderJourney.vue'
import userProfileSetting from '../components/pages/userProfileSetting.vue'
// import axios from 'axios'
// import $ from 'jquery'
// import moment from 'moment'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            modules: [
                Navigation, Pagination, Autoplay, Scrollbar
            ],
            selectedStatus: 'all',
            quotationSelectedStatus: 'active',
            phone: localStorage.getItem("mebrokers.customer.phone.number"),
            isDesktop: false,
        }
    },
    components: {
        HeaderJourney,
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.handleResize(); // Check screen size initially
        window.addEventListener("resize", this.handleResize); // Listen for resize events
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize); // Clean up
    },
    methods: {
        handleResize() {
            this.isDesktop = window.innerWidth > 991; // Adjust breakpoint as needed
        },
    }
}
</script>

<script setup>
import { ref } from 'vue';
// const active = ref(1);

const visible = ref(false);

</script>

<style>

</style>