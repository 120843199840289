<template>

<HeaderJourney />

<div class="journey-page-wrapper">
    <div class="container">
        <h2>Medical Insurance</h2>

        <div class="journey-inner-container">
            <div class="steps-container">
                <!-- Stepper UI -->
                <div class="stepper">
                    <div
                        v-for="(step, index) in steps"
                        :key="index"
                        class="step"
                        :class="{ active: currentStep === index, completed: currentStep > index }"
                    >
                        <div class="circle">
                            <i class="pi pi-check"></i>
                        </div>
                        <span class="label">{{ step.label }}</span>
                    </div>
                </div>
            </div>

            <div class="steps-details" v-if="currentStep === 1">
                <div class="info-detail-container">
                    <h3>Coverage</h3>

                    <div class="insurance-type-container grid mt-2">
                        <div class="col-12 md:col-3 lg:col-3" v-for="item in coverage_types" :key="item">
                            <div class="coverage-type-box" :class="{ active: item.id == user_medical_journey.coverage_type.id }" @click="select_coverage_type(item)" >
                                <div class="insurance-type-icon">
                                    <img :src="item.icon">
                                </div>
                                <div class="insurance-type-info">
                                    <h2 class="m-0">{{item.name}}</h2>
                                    <i class="pi pi-info-circle" v-tooltip.top="item.description"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-6 mt-2 information-section">
                            <span class="p-input-icon-left">
                                <label class="input-label input-label-floating">
                                    Emirates of members
                                </label>
                                <dropdown-prime v-model="user_medical_journey.uae_city" :options="uae_cities" optionLabel="name" placeholder="Select" class="w-full" />
                            </span>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6 mt-2">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Salary Band 
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/money_svgrepo.com.svg">
                                    <dropdown-prime v-model="user_medical_journey.salary_range" :options="salaries" optionLabel="name" placeholder="Select Salary Band" class="w-full form-input-fields" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="seperator-line"><hr /></div>

                <div class="info-detail-container">
                    <h3>Policy Holder</h3>
                    <p>Your details here will be used in policy</p>

                    <div class="insurance-type-container grid">
                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Full Name
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/profile_svgrepo.com.svg">
                                    <inputText-prime type="text" v-model="user_medical_journey.customer.full_name" class="form-input-fields" placeholder="Enter Full Name" />
                                </span>
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Email
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/email_svgrepo.com.svg">
                                    <inputText-prime type="text" v-model="user_medical_journey.customer.email" class="form-input-fields" placeholder="Enter Email" />
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Phone Number
                                </label>
                                <span class="p-input-icon-left">
                                    <vue-tel-input v-model="user_medical_journey.customer.phone_number" mode="auto" :dropdownOptions="dropdownOptions" validCharactersOnly
                                                    :defaultCountry="default_country" @on-input="onInput"
                                                    @validate="valid"></vue-tel-input>
                                    
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Date of Birth
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/calendar-minimalistic_svgrepo.com.svg">
                                    <calendar-prime v-model="user_medical_journey.customer.date_of_birth" :maxDate="new Date(user_medical_journey.customer.limit_date_of_birth)" showIcon iconDisplay="input"
                                    placeholder="Enter DOB" dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)" class="form-input-fields"  />
                                </span>
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Gender
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/gender-intersex-fill_svgrepo.com.svg">
                                    <dropdown-prime v-model="user_medical_journey.customer.gender" :options="gender_categories" optionLabel="name" placeholder="Select Gender" class="w-full form-input-fields" />
                                </span>
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6" v-if="user_medical_journey.customer.gender != null && user_medical_journey.customer.gender.id!=1">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Are you pregnent?
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/gender-intersex-fill_svgrepo.com.svg">
                                    <dropdown-prime v-model="user_medical_journey.customer.is_pregnent" :options="pregnent_options" optionLabel="name" placeholder="Select" class="w-full form-input-fields" />
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="info-detail-container" v-if="(user_medical_journey.coverage_type!= null && user_medical_journey.coverage_type.id == 3) || (user_medical_journey.coverage_type!= null && user_medical_journey.coverage_type.id == 4)">
                    <div class="col-12 md:col-12 lg:col-12 seperator-line pl-0 pr-0"><hr /></div>

                    <h3>Dependents</h3>
                    <p>Details of your family members here to be included in policy coverage</p>

                    <div class="insurance-type-container dependent-list grid" v-for="(item, index) in user_medical_journey.dependants" :key="index">
                        <div class="col-12 md:col-12 lg:col-12">
                            <h3>Dependent {{ index+1 }}</h3>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Age Group
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/profile_svgrepo.com.svg">
                                    <dropdown-prime @change="set_calendar_birth_date(index)" v-model="user_medical_journey.dependants[index].age_group" :options="age_group" optionLabel="name" placeholder="Select Group" class="w-full form-input-fields" />
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Full Name
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/profile_svgrepo.com.svg">
                                    <inputText-prime type="text" v-model="user_medical_journey.dependants[index].full_name" class="form-input-fields" placeholder="Enter Full Name" />
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Date of Birth
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/calendar-minimalistic_svgrepo.com.svg">
                                    <calendar-prime v-model="user_medical_journey.dependants[index].date_of_birth" :maxDate="new Date(user_medical_journey.dependants[index].limit_date_of_birth)" showIcon iconDisplay="input"
                                    placeholder="Enter DOB" dateFormat="dd MM yy" @keypress="stop_writing_on_date($event)"  class="form-input-fields" />
                                </span>
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Gender
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/gender-intersex-fill_svgrepo.com.svg">
                                    <dropdown-prime v-model="user_medical_journey.dependants[index].gender" :options="gender_categories" optionLabel="name" placeholder="Select Gender" class="w-full form-input-fields" />
                                </span>
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-6" v-if="user_medical_journey.dependants[index].gender != null && user_medical_journey.dependants[index].gender.id!=1">
                            <div class="form-fields-main">
                                <label class="input-label input-label-floating">
                                    Gender
                                </label>
                                <span class="p-input-icon-left">
                                    <img class="inputFields-icons" src="../../assets/images/medical-journey-icons/gender-intersex-fill_svgrepo.com.svg">
                                    <dropdown-prime v-model="user_medical_journey.dependants[index].is_pregnent" :options="pregnent_options" optionLabel="name" placeholder="Select Gender" class="w-full form-input-fields" />
                                </span>
                            </div>
                        </div>

                        <div class="col-12 md:col-12 lg:col-12 delete-row">
                            <span @click="delete_dependants(index)">Delete</span>
                        </div>
                    </div>

                 

                    <div class="col-12 md:col-12 lg:col-12 justify-content-end flex pr-3 mt-2">
                        <button-prime @click="add_dependants" class="add-dependent-btn" label="Add Dependent" icon="pi pi-plus" />
                    </div>
                </div>

                <div class="col-12 md:col-12 lg:col-12 seperator-line"><hr /></div>

                <div class="col-12 md:col-12 lg:col-12 justify-content-end flex">
                    <button-prime class="continue-steps-btn" @click="nextStep(1, 2)" label="Continue" />
                </div>
            </div>

            <div class="steps-active-policy" v-if="currentStep === 2">
                <div class="info-detail-container">
                    <h3>Do you have any active policy?</h3>

                    <div class="insurance-type-container grid ml-0 mr-0">
                        <div class="col-12 md:col-12 lg:col-12 pl-0 pr-0">
                            <div class="select-button-container">
                                <selectButton-prime optionLabel="name" v-model="user_medical_journey.current_active_policy" :options="current_active_policy_options" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 seperator-line"><hr /></div>

                    <div class="col-12 md:col-12 lg:col-12 justify-content-between flex">
                        <button-prime class="back-steps-btn" @click="previousStep" label="Back" />
                        <button-prime class="continue-steps-btn" @click="nextStep(2, 3)" label="Continue" />
                    </div>
                </div>
            </div>

            <div class="steps-Declaration"  v-if="currentStep === 3">
                <div class="info-detail-container">
                    <h3>Do you have any critical medical history?</h3>

                    <div class="insurance-type-container grid ml-0 mr-0">
                        <div class="col-12 md:col-12 lg:col-12 pl-0 pr-0">
                            <div class="select-button-container">
                                <selectButton-prime optionLabel="name" v-model="user_medical_journey.critical_medical_history" :options="critical_medical_history_options" />
                            </div>
                        </div>
                        
                        <div class="col-12 md:col-12 lg:col-12 pl-0 pr-0">
                            <div class="checkbox-button-container">
                                <checkbox-prime v-model="medicalHistoryCheck" />
                                <label for="ingredient1" class="ml-2"> I hereby declare that the information provided in this medical insurance application is accurate and complete to the best of my knowledge. I understand that any misrepresentation or omission may affect the validity of the policy. I authorize the insurance provider to verify the details provided and process this application in accordance with their policies and regulations. </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-12 md:col-12 lg:col-12 seperator-line"><hr /></div>

                    <div class="col-12 md:col-12 lg:col-12 justify-content-between flex">
                        <button-prime class="back-steps-btn" @click="previousStep" label="Back" />
                        <button-prime class="continue-steps-btn" label="Continue" :loading="loading" @click="save_quotation"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<dialog-prime v-model:visible="active_policy_alert_modal" header="ALERT" :style="{ width: '25rem' }" position="BottomRight"
    :modal="true" :draggable="false" class="eligible-popup">
    <span class="p-text-secondary block mb-5">You are not eligible for online policy purchase, Kindly contact us
        (whatsapp, email, call) to get detailed pricing.</span>
    <div class="flex justify-content-end gap-2">
        <a :href="'mailto:'+company_email"><button-prime class="support-chatnow-btn" label="Email"
                icon="pi pi-envelope" /></a>
        <a :href="'https://wa.me/'+company_whatsapp_number"><button-prime class="support-chatnow-btn"
                label="Whatsapp" icon="pi pi-whatsapp" /></a>
        <a :href="'tel:'+company_phone_number"><button-prime class="support-chatnow-btn" label="Call"
                icon="pi pi-phone" /></a>
    </div>
</dialog-prime>
<!-- Edit Travel Destination Modal -->
<dialog-prime v-model:visible="visible_thankyou_modal" modal header="" class="thank-you-modal" :style="{ width: '30rem' }">
        <div class="thank-you-modal-body">
            <h2>Thank You</h2>
            <p>Our agents will contact you for further support</p>
        </div>
        <div class="flex justify-content-center">
            <router-link to="/medical">
                <button-prime  type="button" label="Continue Browsing" @click="visible_thankyou_modal = false"></button-prime>
            </router-link>
        </div>
    </dialog-prime>
    <!-- / Edit Travel Destination Modal -->
<toast-prime />
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

export default {
    data() {
        return {
            loading : false,
            is_phone_number_valid : false,
            active_policy_alert_modal : false,
            visible_thankyou_modal : false,
            coverage_types: [],
            uae_cities : [],
            salaries : [],
            gender_categories : [],
            pregnent_options : [{name : 'Yes'}, {name : 'No'}],
            critical_medical_history_options : [{name : 'Yes'}, {name : 'No'}],
            current_active_policy_options : [{name : 'Yes'}, {name : 'No'}],
            age_group : [{name : 'Adult', code : 'adult'}, {name : 'Child', code : 'child'}],
            co_pay_options : [],
            user_medical_journey : {
                status : 'lead',
                quotation_id : null,
                quotation_number : null,
                step : 'coverage_type',
                coverage_type : null,
                uae_city : null,
                critical_medical_history : null,
                salary_range : null,
                co_pay_option : [],
                current_active_policy : null,
                current_insurance_company : null,
                current_policy_expiry_date : null,
                dependants : [
                    {
                        age_group : null,
                        full_name : null,
                        date_of_birth : null,
                        limit_date_of_birth : null, 
                        gender : null,
                        is_pregnent : null
                   }
                ],
                customer : {
                    full_name : null,
                    email : null,
                    phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
                    date_of_birth : null,
                    limit_date_of_birth : new Date(new Date().setTime(new Date().valueOf() - 19 * 365 * 24 * 60 * 60 * 1000)), 
                    gender : null,
                    is_pregnent : null
                },
            },

            currentStep: 1,
            steps: [
                { label: "Personal details" },
                { label: "Active Policy" },
                { label: "Declaration" },
                // { label: "Quotations" },
            ],
            
            cities: [
                { name: 'Dubai' },
                { name: 'Abu Dhabi' },
                { name: 'Ajman' },
                { name: 'Sharjah' },
            ],
            
            gender: [
                { name: 'Male' },
                { name: 'Female - Single' },
                { name: 'Female - Married' },
            ],
            
            maritalStatus: [
                { name: 'Single' },
                { name: 'Married' },
                { name: 'Divorced' },
                { name: 'Widowed' },
            ],
            
            salaryBand: [
                { name: 'Below 4000' },
                { name: 'Upto 4000' },
            ],
            
            ageGroup: [
                { name: 'Adult' },
                { name: 'Child' },
            ]
        }
    },
    components: {
        HeaderJourney,
    },
    mounted() {
        this.get_coverage_types();
        this.get_gender_categories();
        this.get_uae_cities();
        this.get_salary_options();
        this.get_companies();
        this.get_co_pay_options();
    },
    methods: {
        add_dependants() {
            this.user_medical_journey.dependants.push({
                age_group : null,
                full_name : null,
                date_of_birth : null,
                limit_date_of_birth : null, 
                gender : null,
                is_pregnent : null
            })
        },
        delete_dependants(index) {
            this.user_medical_journey.dependants.splice(index, 1); 
        },
        set_calendar_birth_date(index) {
            if (this.user_medical_journey.dependants[index].age_group != null && this.user_medical_journey.dependants[index].age_group.code == 'adult') {
                this.user_medical_journey.dependants[index].date_of_birth = null;
                // this.user_medical_journey.dependants[index].date_of_birth = new Date(new Date().setTime(new Date().valueOf() - 19 * 365 * 24 * 60 * 60 * 1000));
                this.user_medical_journey.dependants[index].limit_date_of_birth = new Date(new Date().setTime(new Date().valueOf() - 19 * 365 * 24 * 60 * 60 * 1000));
            }

            if (this.user_medical_journey.dependants[index].age_group != null && this.user_medical_journey.dependants[index].age_group.code == 'child') {
                this.user_medical_journey.dependants[index].date_of_birth = null;
                // this.user_medical_journey.dependants[index].date_of_birth = new Date();
                this.user_medical_journey.dependants[index].limit_date_of_birth = new Date();
            }
          
        },
        nextStep(from, to) {

            if (from == 1 && to == 2) {

                if (this.user_medical_journey.uae_city == null) {
                    this.$toast.add({ severity: 'error', detail: 'Emirate is required!', life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.salary_range == null) {
                    this.$toast.add({ severity: 'error', detail: 'Salary band is required!', life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.customer.full_name == null || this.user_medical_journey.customer.full_name == '') {
                    this.$toast.add({ severity: 'error', detail: "Policy holder's fullname is required!", life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.customer.email == null || this.user_medical_journey.customer.email == '') {
                    this.$toast.add({ severity: 'error', detail: "Policy holder's email is required!", life: 3000 });
                    return false; 
                }
                if (!this.validate_email(this.user_medical_journey.customer.email)) {
                    this.$toast.add({ severity: 'error', detail: "Policy holder's email is incorrect", life: 3000 });
                    return false; 
                }
                if (!this.is_phone_number_valid) {
                    this.$toast.add({ severity: 'error', detail: 'Invalid Phone number!', life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.customer.date_of_birth == null || this.user_medical_journey.customer.date_of_birth == '') {
                    this.$toast.add({ severity: 'error', detail: "Policy holder's date of birth is required!", life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.customer.gender == null) {
                    this.$toast.add({ severity: 'error', detail: "Policy holder's gender is required!", life: 3000 });
                    return false; 
                }
                if (this.user_medical_journey.customer.gender.id != 1 && this.user_medical_journey.customer.is_pregnent == null) {
                    this.$toast.add({ severity: 'error', detail: "Policy holder's pragnent option is required!", life: 3000 });
                    return false; 
                }

                if (this.user_medical_journey.coverage_type.id == 3 || this.user_medical_journey.coverage_type.id == 4) {
                    
                    for (let i = 0; i < this.user_medical_journey.dependants.length; i++) {

                        if (this.user_medical_journey.dependants[i].age_group == null) {
                            this.$toast.add({ severity: 'error', detail: "Dependant "+(i+1)+" age group is required!", life: 3000 });
                            return false;
                        }
                        
                        if (this.user_medical_journey.dependants[i].full_name == null || this.user_medical_journey.dependants[i].full_name == '') {
                            this.$toast.add({ severity: 'error', detail: "Dependant "+(i+1)+" full name is required!", life: 3000 });
                            return false;
                        }

                        if (this.user_medical_journey.dependants[i].full_name.trim().split(/\s+/) && this.user_medical_journey.dependants[i].full_name.trim().split(/\s+/).length < 2) {
                            this.$toast.add({ severity: 'error', detail: "Dependant "+(i+1)+" full name is required!", life: 3000 });
                            return false;
                        }

                        if (this.user_medical_journey.dependants[i].date_of_birth == null) {
                            this.$toast.add({ severity: 'error', detail: "Dependant "+(i+1)+" birth date is required!", life: 3000 });
                            return false;
                        }

                        if (this.user_medical_journey.dependants[i].gender == null) {
                            this.$toast.add({ severity: 'error', detail: "Dependant "+(i+1)+" gender is required!", life: 3000 });
                            return false;
                        }
                        
                        if (this.user_medical_journey.dependants[i].gender.id != 1 && this.user_medical_journey.dependants[i].is_pregnent == null) {
                            this.$toast.add({ severity: 'error', detail: "Dependant "+(i+1)+" pregnent option is required!", life: 3000 });
                            return false;
                        }
                    }

                    

                }

                this.currentStep = 2;

            }

            if (from == 2 && to == 3) {

                if (this.user_medical_journey.current_active_policy == null) {

                    this.$toast.add({ severity: 'error', detail: "Please select the current active policy option!", life: 3000 });
                    return false;

                }

                if (this.user_medical_journey.current_active_policy.name == 'No') {
                    this.active_policy_alert_modal = true;
                    return false;
                }

                this.currentStep = 3;

            }

        },
        save_quotation() {

            if (this.user_medical_journey.critical_medical_history == null) {

                this.$toast.add({ severity: 'error', detail: "Please select the critical medical history option!", life: 3000 });
                return false;

            }

            
            var objt = {};
            objt.customer = {};
            
                
            objt.customer.full_name = this.user_medical_journey.customer.full_name;
            objt.customer.email = this.user_medical_journey.customer.email;
            objt.customer.mobile = this.user_medical_journey.customer.phone_number;
            objt.customer.date_of_birth = moment(this.user_medical_journey.customer.date_of_birth).format("YYYY-MM-DD")
            objt.customer.gender = this.user_medical_journey.customer.gender;
            objt.customer.is_pregnent = this.user_medical_journey.customer.is_pregnent == null ? {name : 'No'} : this.user_medical_journey.customer.is_pregnent;
                
                
            objt.insured_peoples = [];
            objt.insured_peoples[0] = objt.customer;
            objt.insured_peoples[0].is_policy_holder = 1;
            objt.insured_peoples[0].type = 'adult';
            objt.insured_peoples[0].gender = this.user_medical_journey.customer.gender
            objt.insured_peoples[0].is_pregnent = this.user_medical_journey.customer.is_pregnent == null ? {name : 'No'} : this.user_medical_journey.customer.is_pregnent

                

            objt.status = this.user_medical_journey.status;
            objt.quotation_id = this.user_medical_journey.quotation_id;
            objt.coverage_type_id = this.user_medical_journey.coverage_type.id;
            objt.salary_range_id = this.user_medical_journey.salary_range == null ? 0 : this.user_medical_journey.salary_range.id;
            objt.critical_medical_history = this.user_medical_journey.critical_medical_history.name;
            objt.current_active_policy = this.user_medical_journey.current_active_policy == null ? 'No' : this.user_medical_journey.current_active_policy.name;
            objt.current_insurance_company = this.user_medical_journey.current_insurance_company == null ? 0 : this.user_medical_journey.current_insurance_company.id;
            objt.current_policy_expiry_date = moment(new Date).format("YYYY-MM-DD")
            objt.city_id = this.user_medical_journey.uae_city.id;

                
            if (this.user_medical_journey.coverage_type.id == 3 || this.user_medical_journey.coverage_type.id == 4) {
                
                for (let i = 0; i < this.user_medical_journey.dependants.length; i++) {
                    this.user_medical_journey.dependants[i].mobile = null;
                    this.user_medical_journey.dependants[i].email = null;
                    this.user_medical_journey.dependants[i].is_policy_holder = 0;
                    this.user_medical_journey.dependants[i].type = this.user_medical_journey.dependants[i].age_group.code == 'adult' ? 'adult' : 'child';
                    this.user_medical_journey.dependants[i].is_pregnent = this.user_medical_journey.dependants[i].is_pregnent == null ? {name : 'No'} : objt.insured_peoples[i].is_pregnent;
                    objt.insured_peoples.push(this.user_medical_journey.dependants[i]);
                }
    
            }

            objt.co_pay = this.user_medical_journey.co_pay_option;
            console.log('objt', objt);
            this.loading = true;
            
            axios.get(this.api_url+'medical/save_quotation', {
                params : objt
            }).then((response) => {
                this.loading = false;
                if (this.user_medical_journey.critical_medical_history.name == 'Yes') {
                    this.visible_thankyou_modal = true;
                } else {
                    this.user_medical_journey.quotation_id = response.data.data.quotation_id;
                    this.user_medical_journey.quotation_number = response.data.data.quotation_number;
                    localStorage.setItem('mebrokers.customer.medical.journey', JSON.stringify(this.user_medical_journey))
                    localStorage.setItem('mebrokers.customer.phone.number', this.user_medical_journey.customer.phone_number);
                    this.loading = false;
                    if (this.$route.query.is_from != undefined && this.$route.query.is_from == 'admin') {
                        this.$router.push({
                            path : '/medical/plan-listing/'+this.user_medical_journey.quotation_id,
                            query : {
                                is_from : 'admin'
                            }
                        });
                    } else {
                        this.$router.push({
                            path : '/medical/plan-listing/'+this.user_medical_journey.quotation_id,
                        });
                    }
                }
            });



        },
        previousStep() {
            if (this.currentStep > 0) {
                this.currentStep -= 1;
            }
        },
        select_coverage_type(data){
            this.user_medical_journey.coverage_type = data;
            this.scrollToSection();
        },
        scrollToSection() {
            const section = document.querySelector('.information-section');
            if (section) {
                const offset = 100; // Adjust offset as needed
                const sectionTop = section.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                top: sectionTop - offset,
                behavior: 'smooth',
                });
            }
        },
        searchEmirate : function() {
            $('.emirate-list').removeClass('hidden');
            $('.emirate-list:not(:contains('+ this.searchemirate +'))').addClass('hidden');
        },
        number_format : function(number) {
            number = parseFloat(number);
            return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        get_companies : function() {
            axios.get(this.api_url+'get_all_companies').then((response) => {
                this.companies = response.data.data;
            });
        },
        get_salary_options : function() {
            axios.get(this.api_url+'medical/get_salary_options').then((response) => {
                this.salaries = response.data.data;
            });
        },
        get_co_pay_options : function() {
            axios.get(this.api_url+'medical/get_co_pay_options').then((response) => {
                this.co_pay_options = response.data.data;
                this.user_medical_journey.co_pay_option = this.co_pay_options;
            });
        },
        get_nationalities : function() {
            axios.get(this.api_url+'medical/get_nationalities').then((response) => {
                this.nationalities = response.data.data;
            });
        },
        get_coverage_types : function() {
            axios.get(this.api_url+'medical/get_coverage_types').then((response) => {
                this.coverage_types = response.data.data;
                this.user_medical_journey.coverage_type = this.coverage_types[0];
            });
        },
        get_gender_categories : function() {
            axios.get(this.api_url+'medical/get_gender_categories').then((response) => {
                this.gender_categories = response.data.data;
            });
        },
        get_uae_cities : function() {
            axios.get(this.api_url+'get_uae_cities').then((response) => {
                this.uae_cities = response.data.data;
            });
        },
        stop_writing_on_date : function(eve) {
            eve.preventDefault();
        },
        onInput : function(phone, obj) {
            if(obj.valid) {
                this.is_phone_number_valid = true;
            } else {
                this.is_phone_number_valid = false;
            }
        },
        valid : function(obj) {
            if (obj.valid != undefined) {
                if(obj.valid) {
                    this.is_phone_number_valid = true;
                } else {
                    this.is_phone_number_valid = false;
                }
            } else {
                this.is_phone_number_valid = false;
            }
        },
        validate_email : function (email) {
            // eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        number_with_commas : function (x) {
            // eslint-disable-next-line
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>