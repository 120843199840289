<template>
  <HeaderView />

  <main>
    <div class="main-content home-content motor-home-page">
      <section class="main-banner-area">
        <div class="container">
          <div class="main-banner-container">
            <div class="main-banner-title">
              <h2>Protect <img src="../../assets/images/Icons/banner-lock-icon.svg"> your present with MECompare
                Insurance
              </h2>
            </div>
            <div class="banner-form-area">
              <h4>Get motor quote</h4>

              <div class="form-fields-main">
                <span class="p-input-icon-left">
                  <label class="input-label">Select your phone</label>
                  <vue-tel-input v-model="phone_number" mode="auto" :inputOptions="inputOptions"
                    :dropdownOptions="dropdownOptions" validCharactersOnly @on-input="onInput" @validate="valid"
                    :defaultCountry="default_country"></vue-tel-input>

                </span>
                <span v-if="show_invalid_phone_number_message" style="color:red;">{{ invalid_phone_number_message
                  }}</span>
              </div>

              <button-prime @click="validate_phone_number" class="start-btn" :loading="start_button_loader" aria-label="" ro>
                <span class="px-3">Start</span>
                <img src="../../assets/images/Icons/me.svg">
              </button-prime>
            </div>

            <div class="banner-bottom-image">
              <img src="../../assets/images/3156700.png">
            </div>

          </div>
        </div>
      </section>

      <section class="section-padding step-quote-slider">
        <div class="container">
          <div class="section-title-heading">
            <h2>Our 3-Step Quote Process <img class="pl-2" src="../../assets/images/Icons/smile-emoji.svg"></h2>
          </div>

          <div class="width-boxed m-auto">
            <swiper :slides-per-view="1" :space-between="10" setWrapperSize="ture" :navigation="true" :loop="true"
              :pagination="pagination" :modules="modules" :breakpoints="{
                    '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                    '@0.75': { slidesPerView: 1, spaceBetween: 0 },
                    '@1.00': { slidesPerView: 1, spaceBetween: 0 },
                    '@1.50': { slidesPerView: 1, spaceBetween: 0 },
              }" @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide>
                <div class="grid ml-0 mr-0 swiper-slide-column">
                  <div class="col-12 md:col-6 lg:col-6 quote-slider-image-box">
                    <div class="quote-slider-image">
                      <img src="../../assets/images/slider-img-1.png">
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 quote-slider-info-box">
                    <div class="step-quote-swiper-content">
                      <h2>Vehicle Details</h2>
                      <p>Provide your vehicle sequence number or customs card number.</p>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="grid ml-0 mr-0 swiper-slide-column">
                  <div class="col-12 md:col-6 lg:col-6 quote-slider-image-box">
                    <div class="quote-slider-image">
                      <img src="../../assets/images/slider-img-2.png">
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 quote-slider-info-box">
                    <div class="step-quote-swiper-content">
                      <h2>User Details</h2>
                      <p>Share your National ID and personal details for personalized quotes.</p>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="grid ml-0 mr-0 swiper-slide-column">
                  <div class="col-12 md:col-6 lg:col-6 quote-slider-image-box">
                    <div class="quote-slider-image">
                      <img src="../../assets/images/slider-img-3.png">
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-6 quote-slider-info-box">
                    <div class="step-quote-swiper-content">
                      <h2>Compare & Buy</h2>
                      <p>Compare quotes easily and purchase. You can pay in instalments through Tabby for added
                        convenience.</p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>

      <section class="section-padding">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Instant Quote Calculator</h2>
            <p>Find out the cost of your car insurance online in a couple of minutes</p>
          </div>

          <div class="section-gradient width-boxed comprehensive-section">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6">
                <label class="label-badge">Regulated by <span>Insurance Authority</span></label>
                <h2 class="inner-heading-title rtl-text-right">Third Party Liability (TPL)</h2>
                <p class="inner-para-text">Provide your vehicle sequence number or customs card number.</p>
                <button-prime class="inner-sec-btn" aria-label="" label="Calculate your price!"></button-prime>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="inner-section-image third-party-liability">
                  <img src="../../assets/images/third-party-liability.png">
                </div>
              </div>
            </div>
          </div>

          <div class="section-gradient width-boxed comprehensive-section">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6">
                <label class="label-badge">Regulated by <span>Insurance Authority</span></label>
                <h2 class="inner-heading-title rtl-text-right">Comprehensive</h2>
                <p class="inner-para-text">Covers both your liability and potential damages to your car.</p>
                <button-prime class="inner-sec-btn" aria-label="" label="Calculate your price!"></button-prime>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="inner-section-image comprehensive">
                  <img src="../../assets/images/comprehensive.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding mobile-padding-bottom-0">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">What does our insurance cover? <img class="pl-2"
                src="../../assets/images/whyChooseHeadingIcon2.svg"></h2>
            <p>Drive with confidence and let MECompare Insurance take care of the rest.</p>
          </div>

          <div class="tabs-section-area width-boxed">
            <tabView-prime>
              <tabPanel-prime header="Comprehensive">
                <div class="grid w-full">
                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">All-Inclusive Protection</h4>
                      <p class="info-box-description">Covers damages, theft, and various unforeseen events for your
                        peace of
                        mind.</p>
                    </div>
                  </div>

                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Accident Coverage</h4>
                      <p class="info-box-description">Protection for damages, regardless of fault.
                      </p>
                    </div>
                  </div>

                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Theft Security</h4>
                      <p class="info-box-description">Safeguards against theft or attempted theft</p>
                    </div>
                  </div>

                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Natural Disasters</h4>
                      <p class="info-box-description">Coverage for damages from earthquakes, floods, and storms.</p>
                    </div>
                  </div>

                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Vandalism Protection</h4>
                      <p class="info-box-description">Covers intentional acts of vandalism.</p>
                    </div>
                  </div>

                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Fire Damage Coverage</h4>
                      <p class="info-box-description">Protection for damages caused by fires.</p>
                    </div>
                  </div>
                </div>
              </tabPanel-prime>
              <tabPanel-prime header="TPL">
                <div class="grid w-full">
                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Animal Collisions</h4>
                      <p class="info-box-description">Coverage for damages caused by collisions with animals.</p>
                    </div>
                  </div>

                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Roadside Assistance</h4>
                      <p class="info-box-description">Services like towing and battery jump-starts for breakdowns.</p>
                    </div>
                  </div>

                  <div class="col-12 md:col-4 lg:col-4">
                    <div class="info-box-column">
                      <h4 class="info-box-title">Glass and Windshield Coverage</h4>
                      <p class="info-box-description">Repair or replacement for damaged glass.</p>
                    </div>
                  </div>
                </div>
              </tabPanel-prime>
            </tabView-prime>
          </div>
        </div>
      </section>

      <section class="section-padding why-choose-section-area">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3">Why choose <img src="../../assets/images/whyChooseHeadingIcon1.svg"> Compare? <img
                class="pl-2" src="../../assets/images/whyChooseHeadingIcon2.svg"></h2>
            <p>MECompare is the forefront of insurtech in United Arab Emirates, dedicated to revolutionizing <br>
              traditional insurance practices.</p>
          </div>

          <div class="why-choose-inner-area width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/dci.svg">
                  </div>
                  <h4 class="info-box-title">Digital Car Insurance</h4>
                  <p class="info-box-description">Our digital car insurance offers an effortless, instant, and flexible
                    approach to coverage, ensuring a seamless experience.</p>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/sp.svg">
                  </div>
                  <h4 class="info-box-title">Simplified Process</h4>
                  <p class="info-box-description">All you need is your National ID/Iqama and your vehicle sequence
                    number or customs card number to get started.
                  </p>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/pf.svg">
                  </div>
                  <h4 class="info-box-title">Payment Flexibility</h4>
                  <p class="info-box-description">Choose to pay in one go or opt for convenient instalments, whatever
                    suits you best for your flexibility</p>
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <div class="info-box-column">
                  <div class="icon-box-area">
                    <img src="../../assets/images/scp.svg">
                  </div>
                  <h4 class="info-box-title">Swift Claims Processing</h4>
                  <p class="info-box-description">Coverage for damages from earthquakes, floods, and storms.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding effortless-claims-section">
        <div class="container">
          <div class="section-gradient width-boxed">
            <div class="grid w-full">
              <div class="col-12 md:col-6 lg:col-6 relative inner-section-image-mobile-img">
                <div class="inner-section-image-mobile">
                  <img src="../../assets/images/white-mobile-2.png">
                </div>
              </div>

              <div class="col-12 md:col-6 lg:col-6">
                <h2 class="inner-heading-title rtl-text-right">Effortless Claims with Our <br> Web App</h2>
                <p class="inner-para-text">Provide your vehicle sequence number or customs card number.</p>

                <ul class="effortless-claims-ul">
                  <li>
                    <i class="pi pi-check" style="font-size: 1rem"></i>
                    <span>Streamline claims submission</span>
                  </li>
                  <li>
                    <i class="pi pi-check" style="font-size: 1rem"></i>
                    <span>Stay updated on claim progress</span>
                  </li>
                  <li>
                    <i class="pi pi-check" style="font-size: 1rem"></i>
                    <span>Download forms and documents</span>
                  </li>
                </ul>
                
                <button-prime class="file-a-claim" aria-label="" label="File a Claim"></button-prime>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="section-padding insure-now-pay">
        <div class="container">
          <div class="grid width-boxed">
            <div class="col-12 md:col-6 lg:col-6 tabby-plan-box-right">
              <h2 class="inner-heading-title inner-heading-title-tabby">Insure Now Pay Later <br> With <img
                  src="../../assets/images/tabby-icon.svg">
              </h2>
              <p class="inner-para-text">Secure your coverage with MECompare and enjoy the convenience of Tabby's
                flexible payment options. Choose from:</p>

              <div class="grid w-full">
                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pl">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">2 Month Plan</h4>
                    <p class="info-box-description">Play in 2 months at your flexibility</p>
                  </div>
                </div>

                <div class="col-12 md:col-6 lg:col-6 relative tabby-plan-box-pr">
                  <div class="section-gradient section-gradient-plan-box">
                    <h4 class="info-box-title">4 Month Plan</h4>
                    <p class="info-box-description">Play in 4 months at your flexibility</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 md:col-6 lg:col-6 relative">
              <div class="inner-section-image">
                <img src="../../assets/images/tabby-mobile.png">
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <section class="section-padding faq-accordion-section">
        <div class="container">
          <div class="section-title-heading">
            <h2 class="mb-3 flex align-items-center justify-content-center">FAQ</h2>
          </div>

          <div class="faq-accordion-card width-boxed">
            <accordion-prime :activeIndex="0">
              <accordionTab-prime header="How do I get a quote for insurance products offered by MECompare?">
                <p class="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo
                  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="What sets MECompare apart from other insurance providers?">
                <p class="m-0">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim
                  ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                  eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
                  modi.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="Can I customize my insurance coverage with MECompare?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime header="How can I file a claim with MECompare?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
              <accordionTab-prime
                header="Does Lime Insurance offer any additional services or benefits beyond basic coverage?">
                <p class="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                  deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                  provident, similique sunt in culpa qui
                  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                  expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                  minus.
                </p>
              </accordionTab-prime>
            </accordion-prime>
          </div>
        </div>
      </section>
    </div>
  </main>

  <FooterView />

  <!-- OTP Modal Html Start -->
  <dialog-prime class="otp-modal" v-model:visible="otp_modal" modal header="Otp" :style="{ width: '36rem' }">

    <div class="modal-content-otp">
      <h3>Enter your OTP</h3>
      <span class="p-text-secondary block mb-5">To proceed with your request, please enter the OTP (One-Time Password)
        sent to your registered mobile number.</span>
        
      <InputOtp-prime v-model="otp_code" :length="6" />

      <div class="flex justify-content-center gap-2 mt-5">
        <button-prime type="button" label="Resend OTP" severity="secondary" :loading="start_button_loader" @click="send_otp('resend')" class="resend-otp-btn"></button-prime>
        <button-prime type="button" label="Submit" :loading="otp_submit_button_loader" class="submit-otp-btn" @click="verify_otp"></button-prime>
      </div>

      <div class="flex justify-content-center gap-2 mb-5">
        <button-prime type="button" label="Cancel" severity="secondary" class="cancel-otp-popupbtn" @click="otp_modal = false"></button-prime>
      </div>

      <span class="p-text-secondary block">The OTP is valid for a limited time. Ensure you enter it promptly to
        avoid
        expiration. If you haven't received the OTP within a few minutes, you can click "Resend OTP" to receive a new
        one.</span>
    </div>
  </dialog-prime>
  <!-- / OTP Modal Html End -->
  <toast-prime />
</template>

<script>
import axios from 'axios'
import HeaderView from '../Header/HeaderView.vue'
import FooterView from '../Footer/FooterView.vue'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    components: {
      HeaderView,
      FooterView,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        start_button_loader : false,
        otp_submit_button_loader : false,
        otp_modal : false,
        otp_code : null,
        finishMounted: false,
        default_country : 'AE',
        phone_number : localStorage.getItem("mebrokers.customer.phone.number") == null ? null : localStorage.getItem("mebrokers.customer.phone.number"),
        is_phone_number_invalid : false,
        invalid_phone_number_message : 'Invalid Phone Number',
        show_invalid_phone_number_message : false,
        modules: [Navigation, Pagination, Autoplay, Scrollbar, A11y],
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
        inputOptions : { 
          // dynamicPlaceholder : true,
          showDialCode : true,
          placeholder : 'Enter Phone Number'
          
        },
        dropdownOptions : {
          showDialCodeInList : true,
          showFlags : true,
          showSearchBox : true,
          
        }
      }
        
    },
    mounted() {
      localStorage.removeItem('mebrokers.customer.motor.journey');
      localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
    },
    methods : {
      send_otp : function(type) {
          this.start_button_loader = true;
          axios.get(this.api_url+'sms/send_otp', {
          params : {
              mobile : this.phone_number.replaceAll(' ', '')
          }
          }).then((response) => {
              if (response.data.data == true) {
                this.start_button_loader = false;
                this.otp_modal = true;
                if (type == 'resend') {
                  this.$toast.add({ severity: 'success', detail: 'Otp sent successfully!', life: 3000 });
                }
              }
          });
      },
      verify_otp : function() {
          if (this.otp_code == null || this.otp_code == '' || this.otp_code.length < 6) {
            this.$toast.add({ severity: 'error', detail: 'otp required!', life: 3000 });
            return false;
          }

          this.otp_submit_button_loader = true;
          axios.get(this.api_url+'sms/verify_otp', {
          params : {
              mobile : this.phone_number.replaceAll(' ', ''),
              otp_code : this.otp_code
          }
          }).then((response) => {
              if (response.data.data == true) {
                this.otp_submit_button_loader = false;
                this.otp_modal = false;
                this.$toast.add({ severity: 'success', detail: 'Otp verified!', life: 3000 });
                localStorage.setItem("mebrokers.customer.phone.number", this.phone_number.replaceAll(' ', ''));
                localStorage.setItem("mebrokers.customer.phone.number.verified", JSON.stringify({mobile:this.phone_number.replaceAll(' ', ''), verified:'yes'}));
                localStorage.removeItem('mebrokers.customer.motor.journey');
                localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
                this.$router.push({
                    name : 'motor-journey',
                });
              } else {
                this.$toast.add({ severity: 'error', detail: 'Otp not verified!', life: 3000 });
                this.otp_submit_button_loader = false;
              }
          });
      },
      onInput : function(phone, obj) {
        if(obj.valid) {
          this.is_phone_number_invalid = false;
        } else {
          this.is_phone_number_invalid = true;
        }
      },
      validate_phone_number : function() {
        if(!this.is_phone_number_invalid) {
          this.show_invalid_phone_number_message = false;
          
          localStorage.setItem("mebrokers.customer.phone.number", this.phone_number.replaceAll(' ', ''));
          if (localStorage.getItem("mebrokers.customer.phone.number.verified") != null && localStorage.getItem("mebrokers.customer.phone.number") != null) {
            var parse_data = JSON.parse(localStorage.getItem("mebrokers.customer.phone.number.verified"));
            if ((parse_data.mobile == localStorage.getItem("mebrokers.customer.phone.number")) && parse_data.verified == 'yes') {
                localStorage.removeItem('mebrokers.customer.motor.journey');
                localStorage.removeItem('mebrokers.customer.motor.plans.comparison');
                this.$router.push({
                    name : 'motor-journey',
                });
            } else {
              this.send_otp('first_send');
            }
          } else {
            this.send_otp('first_send');
          }
        } else {
          this.show_invalid_phone_number_message = true;
          return false;
        }
      },
      valid : function(obj) {
        if (obj.valid != undefined) {
          if(obj.valid) {
            this.show_invalid_phone_number_message = false;
          }
        }
      },
      
    }
}
</script>


<style>

</style>