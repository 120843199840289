<template>
    <div class="signin-wrapper signin-wrapper-meCompare verify-otp">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-leftArea">
                <div class="login-img">
                    <img class="" src="/login-bg.png">
                </div>
            </div>

            <div class="col-12 md:col-6 lg:col-6 signIn-form-rightArea">
                <!-- <div class="logo-area">
                    <router-link to="/">
                        <img class="site-logo" src="/logo.png">
                    </router-link>
                </div> -->

                <div class="welcome-text-sign">
                    <h2>Verify Yourself!</h2>
                    <p>Verify your account with otp sent to you</p>
                </div>

                <!-- Sign In Form -->
                <div class="sign-form-area" id="signIn-from">
                    <div class="form-fields-main mb-0">
                        <span class="p-input-icon-left">
                            <InputOtp-prime v-model="otp_code" :length="6" />
                        </span>
                    </div>

                    <div class="form-fields-main text-center mb-0">
                       <button-prime class="login-btn mb-2" label="Verify" rounded @click="verify_otp" :loading="verify_loader"/>
                       <p class="botton-text-label">
                            <button-prime class="resend-code" :loading="resend_loader" @click="send_otp">Resend code</button-prime>
                       </p>
                    </div>
                </div>
                <!-- / Sign In Form -->
            </div>
        </div>
        <toast-prime />
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            otp_code: null,
            resend_loader : false,
            verify_loader : false
        }
    },
    mounted() {
        
    },
    methods : {
        send_otp : function() {
          this.resend_loader = true;
          axios.get(this.api_url+'customer-registration-otp', {
          params : {
              unique_id : this.$route.params.id
          }
          }).then((response) => {
              if (response.data.data == true) {
                this.resend_loader = false;
                this.$toast.add({ severity: 'success', detail: 'Otp sent successfully!', life: 3000 });
                
              }
          });
      },
      verify_otp : function() {
          if (this.otp_code == null || this.otp_code == '' || this.otp_code.length < 6) {
            this.$toast.add({ severity: 'error', detail: 'otp required!', life: 3000 });
            return false;
          }

          this.verify_loader = true;
          axios.get(this.api_url+'verify-customer-registration-otp', {
          params : {
              unique_id : this.$route.params.id,
              otp_code : this.otp_code,
              type : this.$route.params.code
          }
          }).then((response) => {
              if (response.data.data.status == true) {
                this.verify_loader = false;
                localStorage.setItem("mebrokers.customer.phone.number", response.data.data.phone.replaceAll(' ', ''));
                localStorage.setItem("mebrokers.customer.phone.number.verified", JSON.stringify({mobile : response.data.data.phone.replaceAll(' ', ''), verified:'yes'}));
                if (this.$route.params.code == 'verify-mobile') {
                    this.$toast.add({ severity: 'success', detail: 'Otp verified! you can change password now', life: 3000 });
                    setTimeout(function () {
                        this.$router.push({
                            path: '/new-password/'+response.data.data.id,
                        });
                    }.bind(this), 2000);
                }

                if (this.$route.params.code == 'verify-customer-registration') {
                    this.$toast.add({ severity: 'success', detail: 'Otp verified! you can signin now', life: 3000 });
                    setTimeout(function () {
                        this.$router.push({
                            name: 'signin',
                        });
                    }.bind(this), 2000);
                }
    
              } else {
                this.$toast.add({ severity: 'error', detail: 'Otp not verified!', life: 3000 });
                this.verify_loader = false;
              }
          });
      },
    }
}
</script>