<template>
    <div class="signin-wrapper signin-wrapper-meCompare">
        <div class="grid m-0">
            <div class="col-12 md:col-6 lg:col-6 signIn-Info-leftArea">
                <div class="login-img">
                    <img class="" src="/register-bg.png">
                </div>
            </div>

            <div class="col-12 md:col-6 lg:col-6 signIn-form-rightArea justify-content-center">
                <!-- <div class="logo-area">
                    <router-link to="/">
                        <img class="site-logo" src="/logo.png">
                    </router-link>
                </div> -->

                <div class="welcome-text-sign">
                    <h2 class="mb-0">Set New Password</h2>
                    <!-- <p>Enter to get your desired insurance</p> -->
                </div>

                <!-- Form -->
                <div class="sign-form-area" id="signIn-from">
                    <div class="form-fields-main mb-0">
                        <span class="p-input-icon-left">
                            <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                <path d="M2.05819 23.48C2.34732 25.6374 4.12603 27.3276 6.29057 27.4276C8.11193 27.5117 9.96211 27.5556 11.9996 27.5556C14.037 27.5556 15.8872 27.5117 17.7086 27.4276C19.8731 27.3276 21.6518 25.6374 21.941 23.48C22.1297 22.072 22.2853 20.6292 22.2853 19.1597C22.2853 17.6903 22.1297 16.2474 21.941 14.8395C21.6518 12.6821 19.8731 10.9919 17.7086 10.8919C15.8872 10.8078 14.037 10.7639 11.9996 10.7639C9.96211 10.7639 8.11193 10.8078 6.29057 10.8919C4.12603 10.9919 2.34732 12.6821 2.05819 14.8395C1.8695 16.2474 1.71386 17.6903 1.71386 19.1597C1.71386 20.6292 1.8695 22.072 2.05819 23.48Z" stroke="#8A8D90" stroke-width="1.5"/>
                                <path d="M6.21387 10.7639V7.53473C6.21387 4.32457 8.80422 1.72223 11.9996 1.72223C15.1949 1.72223 17.7853 4.32457 17.7853 7.53473V10.7639" stroke="#8A8D90" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.9941 19.1597H12.0057" stroke="#8A8D90" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>

                            <password-prime class="form-input-fields" v-model="customer.password" toggleMask :feedback="false" placeholder="Enter New Password" />
                        </span>
                    </div>

                    <div class="form-fields-main mb-0">
                        <span class="p-input-icon-left">
                            <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                <path d="M2.05819 23.48C2.34732 25.6374 4.12603 27.3276 6.29057 27.4276C8.11193 27.5117 9.96211 27.5556 11.9996 27.5556C14.037 27.5556 15.8872 27.5117 17.7086 27.4276C19.8731 27.3276 21.6518 25.6374 21.941 23.48C22.1297 22.072 22.2853 20.6292 22.2853 19.1597C22.2853 17.6903 22.1297 16.2474 21.941 14.8395C21.6518 12.6821 19.8731 10.9919 17.7086 10.8919C15.8872 10.8078 14.037 10.7639 11.9996 10.7639C9.96211 10.7639 8.11193 10.8078 6.29057 10.8919C4.12603 10.9919 2.34732 12.6821 2.05819 14.8395C1.8695 16.2474 1.71386 17.6903 1.71386 19.1597C1.71386 20.6292 1.8695 22.072 2.05819 23.48Z" stroke="#8A8D90" stroke-width="1.5"/>
                                <path d="M6.21387 10.7639V7.53473C6.21387 4.32457 8.80422 1.72223 11.9996 1.72223C15.1949 1.72223 17.7853 4.32457 17.7853 7.53473V10.7639" stroke="#8A8D90" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.9941 19.1597H12.0057" stroke="#8A8D90" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>

                            <password-prime class="form-input-fields" v-model="customer.password_confirmation" toggleMask :feedback="false" placeholder="Confirm Password" />
                        </span>
                    </div>

                    <div class="form-fields-main text-center mb-0">
                       <button-prime class="login-btn mb-2" :loading="loading" label="Save" rounded  @click="save_password"/>
                       <p class="botton-text-label">
                            Back to
                            <router-link to="/sign-in">
                                <a>Sign In</a>
                            </router-link>
                       </p>
                    </div>
                </div>
                <!-- / Form -->
            </div>
        </div>
        <toast-prime />
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            loading : false,
            customer : {
                password : null,
                password_confirmation : null
            }
        }
    },
    mounted() {
        
    },
    methods : {
      
        save_password(){
            if (!this.customer.password || this.customer.password.length < 8) {
                this.$toast.add({ severity: 'error', detail: 'Password must be at least 8 characters long!', life: 3000 });
                return false;
            }

            if (!this.customer.password_confirmation || this.customer.password !== this.customer.password_confirmation) {
                this.$toast.add({ severity: 'error', detail: 'Passwords do not match!', life: 3000 });
                return false;
            }
            this.loading = true;
            axios.get(this.api_url+'change-customer-password', {
            params : {
                unique_id : this.$route.params.id,
                password : this.customer.password
            }
            }).then((response) => {
                this.loading = false;
                if (response.data.data.status == true) {
                    this.$toast.add({ severity: 'success', detail: response.data.data.message, life: 3000 });
                    setTimeout(function () {
                        this.$router.push({
                            name: 'signin',
                        });
                    }.bind(this), 2000);
                } else {
                    this.$toast.add({ severity: 'error', detail: response.data.data.message, life: 3000 });
                }
            });
        }
        
    }
}
</script>