<template>
    <div class="left-filters-area">
        <div class="upload-profile-container">
            <button-prime class="p-0 transparent edit-btn">
                <i class="pi pi-pencil"></i>
            </button-prime>
            <label for="user-profile">
                <div class="upload-profile-box" style="background-image: url('')">
                    <h4>Upload profile</h4>
                    <input type="file" id="user-profile" />
                </div>
            </label>
        </div>

        <div class="user-profile-infomation-area">
            <div class="user-pro-info-list">
                <label>Name</label>
                <inputText-prime type="text" placeholder="Enter Name" />
            </div>

            <div class="user-pro-info-list">
                <label>Email</label>
                <inputText-prime type="email" placeholder="Enter Email" />
            </div>

            <div class="user-pro-info-list">
                <label>Phone</label>
                <vue-tel-input v-model="phone" mode="auto" placeholder="Enter number"></vue-tel-input>
            </div>
            
            <div class="user-pro-info-list">
                <label>Country</label>
                <dropdown-prime v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Select Country" class="w-full" />
            </div>

            <button-prime label="Save" class="profile-info-btn" />
        </div>
    </div>
</template>

<script>
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            selectedStatus: 'all',
            quotationSelectedStatus: 'active',
            phone: localStorage.getItem("mebrokers.customer.phone.number"),
        }
    },
    components: {
    },
    mounted() {

    },
    methods: {
        
    }
}
</script>

<style>

</style>